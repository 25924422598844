import axios from 'axios'
import store from '@/store'

const appAxios = () => {
  const { token } = store.state?.auth ?? {}
  return axios.create({
    baseURL: `${process.env.VUE_APP_SERVER_DOMAIN}/api`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
export default appAxios
