<script setup>
import appAxios from '@/libs/app-axios'
import { deepClone } from '@/libs/utils'
import {
  defineExpose,
  defineProps,
  defineEmits,
  reactive,
  watchEffect,
  computed,
  onMounted,
  getCurrentInstance,
} from 'vue'

/** @type {TableState} */
const initialTableState = {
  items: [],
  filter: {
    area: '',
  },
  isLoading: false,
}

/** @type {Props} */
const props = defineProps(['pmListId'])
const emits = defineEmits(['on-edit'])
const breakpoints = getCurrentInstance().proxy.$vuetify.breakpoint

const tableState = reactive(deepClone(initialTableState))
/** @type {import('vue').ComputedRef<Set<string>>} */
const tableAreas = computed(() =>
  tableState.items.reduce((prev, next) => {
    if (prev.has(next.area)) return prev
    prev.add(next.area)
    return prev
  }, new Set([]))
)

const tableItems = computed(() =>
  tableState.items.filter((item) => !tableState.filter.area || tableState.filter.area === item.area)
)
/**
 * Description
 * @param {AbortController} controller
 */
const getPMListItems = async (controller) => {
  try {
    tableState.isLoading = true
    /** @type {import('axios').AxiosResponse<PMListItem[]>} */
    const { status, data } = await appAxios().get(
      `preventive-maintenance-lists/${props.pmListId}/items`,
      {
        signal: controller.signal,
      }
    )
    if (status !== 200) throw new Error('oops something went wrong')
    tableState.items = data
  } catch (e) {
    setTimeout(() => getPMListItems(controller), 3000)
    console.error(e.message)
  } finally {
    tableState.isLoading = false
  }
}

/** @type {Array<import('vuetify').DataTableHeader<PMListItem>>} */
const headers = [
  { text: 'Area', value: 'area' },
  { text: 'Task', value: 'task' },
  { text: 'Standard', value: 'standard' },
  { text: 'Remarks', value: 'remarks' },
  // { text: 'Action', value: 'action' },
]

watchEffect((onCleanup) => {
  if (!props.pmListId) throw new Error('PM List Id is undefined or null')
  const controller = new AbortController()
  getPMListItems(controller)
  onCleanup(() => controller.abort())
})

/**
 * Description
 * @param {PMListItem['id']} itemId
 */
const onEditHandler = (itemId) => {
  emits('on-edit', itemId)
}

/**
 * Description
 * @param {PMListItem} item
 */
const update = async (item) => {
  try {
    tableState.isLoading = true
    await appAxios().put(`/preventive-maintenance-list-items/${item.id}`, {
      ...item,
    })
  } catch (e) {
    console.error(e.message)
  } finally {
    tableState.isLoading = false
  }
}

defineExpose({
  getPMListItems,
})
</script>
<style lang="sass" scoped>

.pm-list-index
  text-transform: capitalize
  .filter
    top: 50px
    background: white
    position: sticky
  .remarks-col
    display: flex
    align-items: center
    padding: 0
    margin: 0
  .standard-col
    display: flex
    align-items: center
    gap: 10px
  .mobile-row
    display: flex
    flex-direction: column
    gap: 10px
    td
      display: block
      height: auto
      .field
        display: flex
        justify-content: space-between
        align-items: center
        gap: 10px
        .label
          font-weight: bold
        .v-input--switch
          margin: 0 !important
          padding: 0 !important
</style>
<template>
  <div class="pm-list-index">
    <div class="filter">
      <v-autocomplete
        label="Filter Area"
        outlined
        v-model="tableState.filter.area"
        dense
        :items="Array.from(tableAreas.values())"
      />
    </div>
    <v-simple-table>
      <thead v-if="!breakpoints.mobile">
        <tr>
          <th v-for="header in headers" :key="header.value">
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody v-if="breakpoints.mobile">
        <tr v-for="item in tableItems" class="mobile-row" :key="item.id">
          <td>
            <div class="mobile">
              <div class="field">
                <span class="label">Area</span>
                <span class="value">{{ item.area }}</span>
              </div>
              <div class="field">
                <span class="label">Task</span>
                <span class="value">{{ item.task }}</span>
              </div>
              <div class="field">
                <span class="label">Standard</span>
                <span class="value">{{ item.standard }}</span>
              </div>
              <div class="field">
                <span class="label">Is Standard</span>
                <v-switch
                  dense
                  v-model="item.isStandard"
                  hide-details
                  color="success"
                  :loading="tableState.isLoading"
                  inset
                  @change="update(item)"
                />
              </div>
              <div v-if="tableState.isLoading">Saving data...</div>
              <div class="field" style="padding: 10px 0px">
                <v-text-field
                  label="Remarks"
                  :loading="tableState.isLoading"
                  v-model="item.remarks"
                  hide-details
                  dense
                  @change="update(item)"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="item in tableItems" :key="item.id">
          <td :style="{ textTransform: 'uppercase' }">{{ item.area }}</td>
          <td>{{ item.task }}</td>
          <td class="standard-col">
            <span>{{ item.standard }}</span>
            <v-checkbox v-model="item.isStandard" hide-details dense @change="update(item)" />
          </td>
          <td>
            <v-text-field
              :loading="tableState.isLoading"
              outlined
              v-model="item.remarks"
              hide-details
              dense
              @change="update(item)"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: 'PMListItemIndex',
}
/**
 * @typedef {Object} TableState
 * @property {boolean} isLoading
 * @property {PMListItem[]} items
 * @property {TableFilter} filter
 *
 * @typedef {Object} TableFilter
 * @property {string} area
 *
 * @typedef {Object} PMListItem
 * @property {number} id
 * @property {string} area
 * @property {string} task
 * @property {string} standard
 * @property {boolean} isStandard
 * @property {string|null} remarks
 * @property {string} createdAt
 * @property {string} updatedAt
 *
 * @typedef {Object} Props
 * @property {number} [pmListId]
 */
</script>
