import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=66a24078"
import script from "./Form.vue?vue&type=script&setup=true&lang=js"
export * from "./Form.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Form.vue?vue&type=style&index=0&id=66a24078&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VCombobox,VTextField,VTextarea})
