export const { isArray } = Array
export const { keys } = Object
/**
 * Description
 * @template [T=any]
 * @param {T} object
*/
export const deepClone = object => {
  if (typeof object !== 'object') return object
  if (object instanceof Set) {
    /** @type {any} */
    const set = new Set(object)
    return set
  }
  if (object instanceof Map) return new Map(object)
  const target = Object.assign(isArray(object) ? [] : {}, object)
  for (const key in target) {
    const property = object[key]
    if (typeof property === 'object') {
      target[key] = deepClone(property)
      continue
    }
    target[key] = object[key]
  }
  const result = target
  return result
}

/**
 * Description
 * @param {Date} date
 */
export const formatDateUS = date => {
  /** @type {Intl.DateTimeFormatOptions} */
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

/**
 * Description
 * @param {Date} date
 */
export const formatDateTimeUS = date => {
  /** @type {Intl.DateTimeFormatOptions} */
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour12: true, hour: '2-digit', minute: '2-digit', }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

/**
 * Description
 * @template T
 * @param {T} t
 * @returns {T}
 */
const test = t => {
  if (Array.isArray(t)) return t
  return t
}

/**
 * Description
 * @template [T=Record<string,any>]
 * @param {T} formState
 * @param {T} initialData
 */
export const resetForm = (formState, initialData) => {
  Object.assign(formState, deepClone(initialData))
}

/**
 * Description
 * @param {string} data
 */
export const decodeBase64Blob = (data) => {
  const buffer = atob(data)
  const { length } = buffer
  const arrayBuffer = new ArrayBuffer(length)
  const uintArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < length; i++) {
    uintArray[i] = buffer.charCodeAt(i)
  }
  return uintArray
}

/**
 * Description
 * @template [T=Record<string, any>]
 * @param {T} object
 * @returns {Array<keyof T>}
 */
export const objectKeys = (object) => {
  /** @type {any} */
  const keys = Object.keys(object)

  return keys
}


