<script setup>
import appAxios from '@/libs/app-axios'
import axios from 'axios'
import { reactive, ref, watchEffect } from 'vue'

/** @type {Props} */
const props = defineProps(['pmListId'])

/** @type {{isLoading: boolean; data: string; errors: {floorNumber?: string}}} */
const formState = reactive({
  isLoading: false,
  data: '',
  errors: {},
})

const emits = defineEmits(['onAddFloorForm'])

const onAddFloorForm = async () => {
  try {
    formState.errors = {}
    const { pmListId } = props
    if (!pmListId) throw new Error('props pmListId is undefined')
    formState.isLoading = true
    await appAxios().post(`/preventive-maintenance-lists/${pmListId}/floor-form`, {
      floorNumber: formState.data,
    })
    formState.data = ''
    emits('onAddFloorForm')
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const { errors } = err.response.data
      errors.floorNumber && (formState.errors.floorNumber = errors.floorNumber)
    }
  } finally {
    formState.isLoading = false
  }
}
</script>
<style lang="scss" scoped>
.floor-form {
  display: flex;
  gap: 10px;
  max-width: 600px;
}
</style>
<template>
  <div class="floor-form">
    <v-btn
      :loading="formState.isLoading"
      :disabled="formState.isLoading"
      color="success"
      @click="onAddFloorForm"
      >Add Floor Form</v-btn
    >
    <v-text-field
      :error-messages="formState.errors.floorNumber"
      label="Floor Number"
      dense
      outlined
      hide-details="auto"
      v-model="formState.data"
    />
  </div>
</template>
<script>
/**
 * @typedef {import('./Index.vue').PMListItem} PMListItem
 *
 * @typedef {Object} Props
 * @property {number} [pmListId]
 */
</script>
