<script setup>
import { reactive, defineProps, defineEmits, onBeforeMount, watchEffect, watch } from 'vue'
import { deepClone } from '@/libs/utils'
import axios from 'axios'
import appAxios from '@/libs/app-axios'

/** @type {FormState} */
const initialFormState = {
  isLoading: false,
  data: {
    title: '',
    name: '',
    formNo: '',
    client: '',
    equipment: '',
    remarks: '',
  },
  errors: {},
}

/** @type {FormState} */
const formState = reactive(deepClone(initialFormState))
const props = defineProps(['editId'])
const emits = defineEmits(['on-success', 'on-cancel'])
/** @type {{ selected: null|PMTemplate; items: PMTemplate[]; isLoading: boolean }} */
const pmTemplateState = reactive({
  items: [],
  selected: null,
  isLoading: false,
})
const resetForm = () => {
  const clone = deepClone(initialFormState)
  formState.isLoading = clone.isLoading
  Object.keys(clone.data).forEach(key => {
    formState.data[key] = clone.data[key]
  })
  formState.errors = clone.errors
}

const getPreventiveMaintenanceTemplates = async () => {
  try {
    /** @type {import('axios').AxiosResponse<PMTemplate[]>} */
    const { status, data } = await appAxios().get('/preventive-maintenance-templates')
    if (status !== 200) throw new Error('oops somthing went wrong, retrying in 3 seconds...')
    pmTemplateState.items = data
  } catch (error) {
    setTimeout(() => getPreventiveMaintenanceTemplates(), 3000)
    console.error(error)
  }
}

const serverMutation = async () => {
  if (props.editId) {
    return appAxios().put(`/preventive-maintenance-lists/${props.editId}`, {
      ...formState.data,
    })
  }
  return appAxios().post('/preventive-maintenance-lists', {
    preventiveMaintenanceTemplateId: pmTemplateState?.selected?.id,
    ...formState.data,
  })
}

const onSubmitHandler = async e => {
  try {
    e.preventDefault()
    formState.errors = {}
    formState.isLoading = true
    await serverMutation()
    emits('on-success')
    if (props?.editId) return
    resetForm()
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { errors } = e.response.data
      Object.keys(errors).forEach(key => (formState.errors[key] = errors[key]))
    }
  } finally {
    formState.isLoading = false
  }
}

/**
 * Description
 * @param {AbortController} controller
 */
const getPMListById = async controller => {
  try {
    /** @type {import('axios').AxiosResponse<import('./Index.vue').PMList>} */
    const { status, data } = await appAxios().get(
      `/preventive-maintenance-lists/${props.editId}/edit`,
      {
        signal: controller.signal,
      }
    )
    if (status !== 200) throw new Error('oops something went wrong retying in 3 seconds...')
    Object.keys(formState.data).forEach(key => (formState.data[key] = data[key]))
  } catch (e) {
    if (e instanceof Error) {
      setTimeout(() => getPMListById(controller), 3000)
      console.error(e.message)
    }
  }
}
/** @type {{
  isLoading: boolean
  items: string[]
}} */
const clientList = reactive({
  isLoading: false,
  items: [],
})
const getClientList = async () => {
  try {
    clientList.isLoading = true
    /** @type {import('axios').AxiosResponse<string[]>} */
    const { data } = await appAxios().get('/preventive-maintenance-lists/client-list')
    clientList.items = data
  } catch (e) {
    console.log('error fetching client list retrying in 3sec...')
    setTimeout(() => {
      getClientList()
    }, 3000)
  } finally {
    clientList.isLoading = false
  }
}
/** @type {{
  isLoading: boolean
  items: string[]
}} */
const equipmentNameList = reactive({
  isLoading: false,
  items: [],
})

const getEquipmentNames = async () => {
  try {
    equipmentNameList.isLoading = true
    /** @type {import('axios').AxiosResponse<string[]>} */
    const { data } = await appAxios().get('/preventive-maintenance-lists/name-list')
    equipmentNameList.items = data
  } catch (e) {
    console.log('error fetching name list retrying in 3sec...')
    setTimeout(() => {
      getEquipmentNames()
    }, 3000)
  } finally {
    equipmentNameList.isLoading = false
  }
}

watchEffect(onCleanup => {
  if (!props.editId) return
  const controller = new AbortController()
  getPMListById(controller)
  onCleanup(() => controller.abort())
})

watchEffect(() => {
  if (!pmTemplateState.selected) return
  Object.keys(formState.data).forEach(key => {
    if (!pmTemplateState.selected[key]) return
    formState.data[key] = pmTemplateState.selected[key]
  })
})

const onCancelHandler = () => {
  resetForm()
  emits('on-cancel')
}

/**
 * Description
 * @param {PMTemplate} item
 * @param {string} queryText
 * @param {string} itemText
 * @returns {boolean}
 */
const pmTemplateFilter = (item, queryText, itemText) => {
  const regex = new RegExp(queryText, 'g')
  return regex.test(item.formNo)
}

onBeforeMount(() => {
  getPreventiveMaintenanceTemplates()
  getClientList()
  getEquipmentNames()
})
</script>
<style lang="sass">
.pm-list-form
  display: flex
  flex-direction: column
  gap: 10px
  width: 100%
  .form-no
    text-transform: uppercase
  .actions
    display: flex
    gap: 10px
    *
      flex: 1
</style>
<template>
  <form @submit="onSubmitHandler">
    <div class="pm-list-form">
      <v-autocomplete
        :disabled="pmTemplateState.isLoading"
        label="Form No"
        dense
        outlined
        hide-details="auto"
        :filter="pmTemplateFilter"
        :items="pmTemplateState.items"
        return-object
        v-model="pmTemplateState.selected"
        :loading="pmTemplateState.isLoading"
      >
        <template #selection="{ item }">
          <div :style="{ textTransform: 'uppercase' }">
            {{ item.formNo }}
          </div>
        </template>
        <template #item="{ item }">
          <div :style="{ textTransform: 'uppercase' }">{{ item.formNo }}</div>
        </template>
      </v-autocomplete>
      <v-text-field
        dense
        outlined
        hide-details="auto"
        :error-messages="formState.errors.title"
        v-model="formState.data.title"
        label="Title"
      ></v-text-field>
      <v-text-field
        dense
        outlined
        hide-details="auto"
        :error-messages="formState.errors.formNo"
        v-model="formState.data.formNo"
        label="Form No"
      ></v-text-field>
      <v-combobox
        dense
        outlined
        :loading="equipmentNameList.isLoading"
        :items="equipmentNameList.items"
        hide-details="auto"
        :error-messages="formState.errors.name"
        placeholder="example: H1"
        label="Equipment Name"
        v-model="formState.data.name"
      >
        <!-- <template #label>
          <div style="text-transform: capitalize">
            <span>
              {{ formState.data.equipment }}
            </span>
            <span v-if="formState.data.equipment">&nbsp;</span>
            <span>name</span>
          </div>
        </template> -->
      </v-combobox>
      <v-text-field
        dense
        outlined
        hide-details="auto"
        :error-messages="formState.errors.equipment"
        v-model="formState.data.equipment"
        label="Equipment"
      ></v-text-field>
      <v-combobox
        dense
        outlined
        :loading="clientList.isLoading"
        :items="clientList.items"
        hide-details="auto"
        :error-messages="formState.errors.client"
        v-model="formState.data.client"
        label="Client"
      ></v-combobox>
      <v-textarea
        dense
        outlined
        v-model="formState.data.remarks"
        :error-messages="formState.errors.remarks"
        :rows="5"
        label="Further Remarks"
        v-if="props.editId"
      />
      <div v-if="props.editId" class="actions">
        <v-btn
          :loading="formState.isLoading"
          :disabled="formState.isLoading"
          color="warning"
          type="submit"
          php
          >Update</v-btn
        >
        <v-btn color="error" @click="onCancelHandler" type="button">Close</v-btn>
      </div>
      <v-btn
        v-else
        color="primary"
        :loading="formState.isLoading"
        :disabled="formState.isLoading"
        dark
        type="submit"
        >Submit</v-btn
      >
    </div>
  </form>
</template>
<script>
export default {
  name: 'PMListForm',
}
/**
 * @typedef {Object} FormState
 * @property {FormStateError} errors
 * @property {FormStateData} data
 * @property {boolean} isLoading
 *
 * @typedef {Object} FormStateError
 * @property {String} [title]
 * @property {String} [formNo]
 * @property {String} [equipment]
 * @property {String} [client]
 * @property {String} [name]
 * @property {String} [remarks]
 *
 * @typedef {Object} FormStateData
 * @property {string} title
 * @property {string} formNo
 * @property {string} name
 * @property {string} equipment
 * @property {string} client
 * @property {string|null} remarks
 *
 * @typedef {import('../templates/Index.vue').PreventiveMaintenanceTemplate} PMTemplate
 */
</script>
