<script setup>
import { computed, getCurrentInstance, onBeforeMount, ref, watchEffect } from 'vue'
import PMListIndex from './Index.vue'
import PMListForm from './Form.vue'
import PMListItemTemplate from './items/Template.vue'

/** @type {import('vue').Ref<PMList['id']>} */
const pmListId = ref(null)
const pmListIndexRef = ref(null)

const breakpoints = getCurrentInstance().proxy.$vuetify.breakpoint
/**
 * Description
 * @param {PMList['id']} itemId
 */
const onEditHandler = itemId => {
  const url = new URL(window.location.href)
  url.searchParams.set('pmList', String(itemId))
  window.history.replaceState({}, '', url.href)
  pmListId.value = itemId
}

onBeforeMount(() => {
  const url = new URL(window.location.href)
  const itemId = parseInt(url.searchParams.get('pmList'), 10)
  if (itemId == null) return
  pmListId.value = itemId
})

const onSuccessHandler = () => {
  pmListIndexRef?.value?.getPMList?.()
}

const onCancelHandler = () => {
  const url = new URL(window.location.href)
  url.searchParams.delete('pmList')
  window.history.replaceState({}, '', url.href)
  pmListId.value = null
}
</script>
<style scoped lang="sass">
.pm-list-template
  display: flex
  flex-direction: column
  gap: 10px
  .box-bubble
    padding: 10px
    border-radius: 10px
    border: 1px solid #cfcdcd
  .headers
    display: flex
    gap: 10px
    .index
      flex: 1
    .pm-list-item
      flex: 1
    &.mobile
      flex-direction: column
</style>
<template>
  <div class="pm-list-template">
    <div :class="`headers ${breakpoints.mobile && 'mobile'} test`">
      <div class="form box-bubble">
        <PMListForm
          :edit-id="pmListId"
          @on-success="onSuccessHandler"
          @on-cancel="onCancelHandler"
        />
      </div>
      <div class="index box-bubble" v-if="!pmListId">
        <PMListIndex ref="pmListIndexRef" @on-edit="onEditHandler" />
      </div>
    </div>
    <div class="pm-list-item">
      <PMListItemTemplate v-if="pmListId" :pm-list-id="pmListId" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PMListTemplate',
}
/**
 * @typedef {import('./Index.vue').PMList} PMList
 */
</script>
